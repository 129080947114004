import * as React from "react"
import { Linking, StyleProp, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, spacing, typography } from "../theme"
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons"
import SimpleLineIcons from "@expo/vector-icons/SimpleLineIcons"
import { ListItem } from "./ListItem"
import { canOpenURL } from "expo-linking"
import { Button } from "./Button"
import { useBreakpointValue } from "native-base"
import { shareVCard, VCardProps } from "../utils/vCard"

export interface ContactListProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>

  items?: ContactItem[]
}

type ContactItem = {
  title: string
  subTitle: string
  value: string
  icon: React.ComponentProps<typeof MaterialCommunityIcons>["name"]
  color: string
}

const CONTACT_ITEMS: ContactItem[] = [
  {
    title: "WhatsApp",
    subTitle: "(305)713-6249",
    value: "https://wa.me/13057136249?text=I'm%20interested%20in%20your%20bartending%20services.",
    icon: "whatsapp",
    color: "#25D366",
  },
  {
    title: "Instagram",
    subTitle: "@reign_catering_miami",
    value: "https://www.instagram.com/reign_catering_miami",
    icon: "instagram",
    color: "#C13584",
  },
]

/**
 * Reusable contact list component
 */
export const ContactList = observer(function ContactList(props: ContactListProps) {
  const { style, items = CONTACT_ITEMS } = props
  const $styles = [$container, style]

  const downloadTxtFile = (vcfText) => {
    const element = document.createElement("a")
    const file = new Blob([vcfText], { type: "text/vcard;charset=utf-8" })
    element.href = URL.createObjectURL(file)
    element.download = "ReignCatering.vcf"
    document.body.appendChild(element)
    element.click()
  }

  const dynamicLogoSize = useBreakpointValue({
    base: LOGO_SIZE_SMALL / 1.5,
    sm: LOGO_SIZE_SMALL / 1.5,
    md: LOGO_SIZE_SMALL,
  })

  const itemTitleSize = useBreakpointValue({
    base: 32 / 2,
    sm: 32 / 2,
    md: 24,
  })
  const itemSubtitleSize = useBreakpointValue({
    base: 24 / 2,
    sm: 24 / 2,
    md: 16,
  })

  return (
    <View style={$styles}>
      <Button
        style={$contactStyle}
        text="Save Contact"
        onPress={() => {
          const cardProps: VCardProps = {
            firstName: "Angelica",
            lastName: "Gianos",
            organization: "Reign Catering",
            workPhone: "(305) 713-6249",
            title: "Mixologist",
            workUrl: "https://reigncateringmiami.com",
            // cellPhone: "(305) 713-6249",
            role: "Mixologist",
            workEmail: "angelica@reigncateringmiami.com",
            socialUrls: [],
          }
          const cardValue = shareVCard(cardProps)

          downloadTxtFile(cardValue)
        }}
        RightAccessory={() => (
          <MaterialCommunityIcons
            name="download"
            size={dynamicLogoSize}
            color={colors.palette.primary}
          />
        )}
      />
      {items.map((item, index) => {
        return (
          <ListItem
            key={index}
            containerStyle={$itemStyle}
            text={item.title}
            subTitle={item.subTitle}
            textStyle={[$itemTextStyle, { fontSize: itemTitleSize }]}
            subTitleTextStyle={[$itemsubTitleTextStyle, { fontSize: itemSubtitleSize }]}
            RightComponent={
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <MaterialCommunityIcons
                  name={item.icon}
                  size={dynamicLogoSize}
                  color={item.color}
                />
                <SimpleLineIcons
                  style={{ fontWeight: "600" }}
                  name={"arrow-right"}
                  size={dynamicLogoSize / 2}
                  color={item.color}
                />
              </View>
            }
            onPress={() => {
              canOpenURL(item.value).then((supported) => {
                if (supported) {
                  Linking.openURL(item.value)
                } else {
                  console.log("Don't know how to open URI: " + item.value)
                }
              })
            }}
            // style={$welcomeFace}
            // size={LOGO_SIZE}
          ></ListItem>
        )
      })}
    </View>
  )
})

const LOGO_SIZE_SMALL = 150 / 3

const $container: ViewStyle = {
  justifyContent: "center",
  width: "90%",
}

const $text: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: 14,
  color: colors.palette.primary500,
}
const $itemStyle: ViewStyle = {
  marginVertical: spacing.small,
  padding: spacing.extraSmall,
  paddingLeft: spacing.small,
  backgroundColor: colors.palette.neutral1300,
  borderRadius: 13,
}
const $contactStyle: ViewStyle = {
  justifyContent: "space-between",
  backgroundColor: colors.palette.neutral200,
  width: "75%",
  alignSelf: "center",
}

const $itemTextStyle: TextStyle = {
  color: colors.palette.neutral100,
  fontSize: 28,
  fontWeight: "bold",
  // borderColor: "blue",
  // borderWidth: 3,
  alignSelf: "flex-start",
}

const $itemsubTitleTextStyle: TextStyle = {
  fontSize: 16,
  color: colors.palette.neutral1500,
  fontWeight: "600",
  alignSelf: "flex-start",
}
