import vCard from "vcards-js"

export type VCardProps = {
  firstName: string
  lastName: string
  organization: string
  workPhone: string
  title: string
  workUrl: string
  cellPhone: string
  role: string
  workEmail: string
  socialUrls: []
}

export function shareVCard(options: VCardProps): string {
  var contact = vCard()

  //set basic properties shown before
  contact.firstName = options.firstName
  // contact.middleName = "J"
  contact.lastName = options.lastName
  contact.organization = options.organization

  //link to image
  // contact.photo.attachFromUrl(
  //   "https://avatars2.githubusercontent.com/u/5659221?v=3&s=460",
  //   "JPEG",
  // )

  //or embed image
  // contact.photo.attachFromUrl("/path/to/file.jpeg")

  contact.workPhone = options.workPhone
  // contact.birthday = new Date("01-01-1985")
  contact.title = options.title
  // contact.url = "https://github.com/enesser"
  contact.workUrl = options.workUrl
  // contact.note = "Notes on Eric"

  //set other vitals
  // contact.nickname = "Scarface"
  // contact.namePrefix = "Mr."
  // contact.nameSuffix = "JR"
  // contact.gender = "M"
  // contact.anniversary = new Date("01-01-2004")
  contact.role = options.role

  //set other phone numbers
  // contact.homePhone = "312-555-1313"
  contact.cellPhone = options.cellPhone
  // contact.pagerPhone = "312-555-1515"

  // set fax/ facsimile numbers
  // contact.homeFax = "312-555-1616"
  // contact.workFax = "312-555-1717"

  // set email addresses
  // contact.email = "angelica@reigncateringmiami.com"
  contact.workEmail = options.workEmail
  //set logo of organization or personal logo (also supports embedding, see above)
  // contact.logo.attachFromUrl("https://avatars2.githubusercontent.com/u/5659221?v=3&s=460", "JPEG")

  //set URL where the vCard can be found
  // contact.source = "http://mywebpage/myvcard.vcf"

  //set address information
  // contact.homeAddress.label = "Home Address"
  // contact.homeAddress.street = "123 Main Street"
  // contact.homeAddress.city = "Chicago"
  // contact.homeAddress.stateProvince = "IL"
  // contact.homeAddress.postalCode = "12345"
  // contact.homeAddress.countryRegion = "United States of America"

  // contact.workAddress.label = "Work Address"
  // contact.workAddress.street = "123 Corporate Loop\nSuite 500"
  // contact.workAddress.city = "Los Angeles"
  // contact.workAddress.stateProvince = "CA"
  // contact.workAddress.postalCode = "54321"
  // contact.workAddress.countryRegion = "United States of America"

  //set social media URLs
  contact.socialUrls["whatsapp"] = "3057136249"
  contact.socialUrls["instagram"] = "reign_catering_miami"

  // contact.socialUrls["facebook"] = "https://..."
  // contact.socialUrls["linkedIn"] = "https://..."
  // contact.socialUrls["twitter"] = "https://..."
  // contact.socialUrls["flickr"] = "https://..."
  // contact.socialUrls["custom"] = "https://..."

  //you can also embed photos from files instead of attaching via URL
  // contact.photo.embedFromFile("photo.jpg")
  // contact.logo.embedFromFile("logo.jpg")

  contact.version = "3.0" //can also support 2.1 and 4.0, certain versions only support certain fields

  //save to file
  // const documentPath = RNFS.DocumentDirectoryPath
  // contact.saveToFile(`${documentPath}/eric-nesser.vcf`)

  //get as formatted string
  // console.log(contact.getFormattedString(), "formatted string")
  return contact.getFormattedString()
}
